export function stringToNumberOrUndefined(s: string | undefined | null): number | undefined {
  if (!s || Number.isNaN(+s)) {
    return undefined;
  }
  return Number.parseInt(s);
}

export const capitalizeWord = (word: string) =>
  word ? (word.length > 1 ? word[0].toUpperCase() + word.substring(1).toLowerCase() : word[0].toUpperCase()) : word;

export const generateRandomPassword = () => {
  return Math.random().toString(36).slice(2) + Math.random().toString(36).toUpperCase().slice(2);
};

export const centerDistanceToKm = (distance: number) => {
  if (distance === 0) {
    return "0 m unna";
  }
  const km = distance / 1000;
  if (km > 1) {
    return `${Math.round(km)} km unna`;
  }
  return `${Math.round(km * 1000)} m unna`;
};

export function formatPhoneNumber(inputPhoneNumber: string): string {
  let formattedNumber = "";
  let phoneNumber = inputPhoneNumber;

  if (phoneNumber.startsWith("+")) {
    formattedNumber += "+";
    phoneNumber = phoneNumber.slice(1);
  }

  const countryCode = phoneNumber.slice(0, 2);
  const restOfNumber = phoneNumber.slice(2);

  formattedNumber += countryCode;
  for (let i = 0; i < restOfNumber.length; i += 2) {
    formattedNumber += ` ${restOfNumber.slice(i, i + 2)}`;
  }

  return formattedNumber;
}
